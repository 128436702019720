angular.module(config.app.name).component('cobuyer', {
    templateUrl: 'components/procedure/components/cobuyer.component.html',
    bindings: {
        index: '<',
        person: '<',
        saveCoBuyer: '&'
    },
    controller: function () {
        var self = this;

        self.person = {
            type: null, // 'personne-physique',
            title: null,
            firstname: null,
            lastname: null,
            marriedname: null,
            company: null
        };

        self.$onInit = function () {
        };

        self.onPersonTypeChange = function () {
            if (self.person.type === 'personne-morale') {
                self.person.title = self.person.firstname = self.person.lastname = self.person.marriedname = null;
            } else {
                self.person.company = null;
            }
        };

        self.save = function () {            
            self.saveCoBuyer({
                index: self.index,
                cobuyer: self.person
            });
        };
    }
});